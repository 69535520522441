import { render, staticRenderFns } from "./Accordion.vue?vue&type=template&id=42b4b650&scoped=true&"
import script from "./Accordion.vue?vue&type=script&lang=js&"
export * from "./Accordion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b4b650",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42b4b650')) {
      api.createRecord('42b4b650', component.options)
    } else {
      api.reload('42b4b650', component.options)
    }
    module.hot.accept("./Accordion.vue?vue&type=template&id=42b4b650&scoped=true&", function () {
      api.rerender('42b4b650', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8zwe7AaU3jkqp/JS/comp/Accordion.vue"
export default component.exports